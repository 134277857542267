"use client";
import { usePathname } from "next/navigation";
import React from "react";
import Providers from "../providers";
import { Navbar, Tab, Tabs } from "@nextui-org/react";
import FixedToastContainer from "@/components/FixedToastContainer";
import Script from "next/script";

export type NavBarProps = {
  derivationsAvailable: boolean | undefined;
  administrationAvailable: boolean | undefined;
  bedsAvailable: boolean | undefined;
  chatAvailable: boolean | undefined;
  children: any;
};

export const NavBarComponent = ({
  administrationAvailable,
  bedsAvailable,
  chatAvailable,
  derivationsAvailable,
  children,
}: NavBarProps) => {
  const pathname = usePathname();

  const tabConfigs = [
    {
      key: "/derivations",
      title: "Derivaciones",
      visible: derivationsAvailable,
    },
    { key: "/chats", title: "Chat", visible: chatAvailable },
    {
      key: "/administration",
      title: "Administración",
      visible: administrationAvailable,
    },
    { key: "/beds", title: "Camas", visible: bedsAvailable },
    { key: "/configuration", title: "Configuración", visible: true },
  ];

  const showNavbar = [
    "/chats",
    "/administration",
    "/derivations",
    "/configuration",
    "/beds",
  ].some((path) => pathname.endsWith(path));

  return (
    <main className="h-screen bg-default bg-cover">
      <div className="h-full sm:py-8 sm:max-w-lg mx-auto bg-default bg-cover bg-center bg-no-repeat">
        <div className="bg-white h-full w-full relative sm:rounded-xl shadow-md overflow-x-hidden overflow-y-auto scroll scroll1">
          <Providers>
            {showNavbar ? (
              <>
                <Navbar
                  className="z-50 w-full m-0 px-4 pt-4 pb-2 items-start"
                  classNames={{ wrapper: "p-0 h-fit m-0" }}
                >
                  <Tabs
                    aria-label="menu"
                    selectedKey={pathname}
                    className="flex justify-start items-start"
                    fullWidth
                    size="md"
                    radius="sm"
                  >
                    {tabConfigs.map(
                      ({ key, title, visible }) =>
                        visible && (
                          <Tab
                            shouldSelectOnPressUp
                            key={key}
                            title={title}
                            href={key}
                            className="text-xs sm:text-sm"
                          />
                        )
                    )}
                  </Tabs>
                </Navbar>
                <div className="h-[90%]">{children}</div>
              </>
            ) : (
              children
            )}
          </Providers>
        </div>
      </div>
      <FixedToastContainer position="top-right" />
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-KQRDZZJP6V"
      />
      <Script async src="/scripts/googleAnalyticsScript.js" />
    </main>
  );
};

export default NavBarComponent;
